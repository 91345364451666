import React from "react";
import MasterLayout from "../Layout/MasterLayout";
const Dashboard  = ()=>{
    return <>
    <MasterLayout>
        <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav>
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#">Home</a></li>
            <li className="breadcrumb-item">Forms</li>
            <li className="breadcrumb-item active">Elements</li>
            </ol>
        </nav>
        </div>
    </MasterLayout>
    </>
}

export default Dashboard;