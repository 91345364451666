import axios,{wp_instance} from "../Interceptor";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../Constants";

export const ExecuteRule = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/apply_rule_2",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            data: response?.data?.data,
            payload:"data received",
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            data: response?.response?.data?.data,
            payload: "error received",
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
};

export const checkRuleResult = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/admin/check_rule_result",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            data: response?.data?.data,
            payload:"data received",
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            data: response?.response?.data?.data,
            payload: "error received",
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
};

export const addRule = (action) => {
    return (dispatch) => {
      axios({
        url: api_base + "/admin/save_rule",
        method: "post",
        data: action.payload,
      }).then(
        (response) => {
          if (response?.status == HTTP_OK) {
            dispatch({
              type: "FORM_SUCCESS",
              payload: response?.data?.data?.message,
              key: action.key,
            });
          } else {
            dispatch({
              type: "FORM_ERROR",
              payload: response?.response?.data?.data,
              moreError: response?.response?.data?.data,
              key: action.key,
            });
          }
        },
        (error) => {
          dispatch({
            type: "FORM_ERROR",
            payload: error?.response?.data?.data,
            moreError: error?.response?.data?.data,
            key: action.key,
          });
        }
      );
    };
};

export const addClient = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/admin/add_client",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data?.message,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data?.data,
            moreError: response?.response?.data?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data?.data,
          moreError: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
};

export const ruleList = (action) => {
    return (dispatch) => {
      axios({
        url: api_base + "/admin/rule_list",
        method: "get",
      }).then(
        (response) => {
          if (response?.status == HTTP_OK) {
            dispatch({
              type: "GET_SUCCESS",
              payload: response?.data?.data,
              key: action.key,
            });
          } else {
            dispatch({
              type: "GET_ERROR",
              payload: response?.response?.data?.data,
              key: action.key,
            });
          }
        },
        (error) => {
            dispatch({
                type: "GET_ERROR",
                payload: error?.response?.data?.data,
                key: action.key,
            });
        }
      );
    };
};

export const deleteRule = (action)=>{
  return (dispatch) => {
    axios({
      url: api_base + "/admin/delete_rule",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data?.message,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data?.data,
            moreError: response?.response?.data?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data?.data,
          moreError: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
}

export const editRule = (action)=>{
  return (dispatch) => {
    axios({
      url: api_base + "/admin/update_rule_detail",
      method: "put",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data?.message,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data?.data,
            moreError: response?.response?.data?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data?.data,
          moreError: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
}

export const getRuleDetail = (action)=>{
  return (dispatch) => {
    axios({
      url: api_base + "/admin/rule_detail",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data?.data,
            moreError: response?.response?.data?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ERROR",
          payload: error?.response?.data?.data,
          moreError: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
}

export const clientList = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/admin/client_list",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data?.data,
            key: action.key,
          });
        }
      },
      (error) => {
          dispatch({
              type: "GET_ERROR",
              payload: error?.response?.data?.data,
              key: action.key,
          });
      }
    );
  };
};

export const scheduleRule = (action)=>{
  return (dispatch)=>{
    axios({
      url: api_base + "/admin/set_scheduler",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            data: response?.data?.data,
            payload:response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            data: response?.response?.data?.message,
            payload:response?.response?.data?.message,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data?.data,
          key: action.key,
        });
      }
    )
  }
}

export const sendViaWhatsapp = (action)=>{
  return (dispatch) => {
    wp_instance({
      url: "https://wext.ainocular.com:8443/api/v1/chat/open/messages",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          console.log('response ',response);
          // dispatch({
          //   type: "FORM_SUCCESS",
          //   payload: response?.data?.msg,
          //   key: action.key,
          // });
        } else {
          console.log('response err',response);
          // dispatch({
          //   type: "FORM_ERROR",
          //   payload: response?.response?.data.message,
          //   key: action.key,
          // });
        }
      },
      (error) => {
        console.log('error',error);
        // dispatch({
        //   type: "FORM_ERROR",
        //   payload: error?.response?.data.message,
        //   key: action.key,
        // });
      }
    );
};
  return (dispatch) => {
    axios({
      url: api_base + "/admin/send_result_on_whatsapp",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        console.log('response',response);

        if (response?.status == HTTP_OK) {
          // dispatch({
          //   type: "FORM_SUCCESS",
          //   data: response?.data?.data,
          //   payload:"data received",
          //   key: action.key,
          // });
        } else {
          // dispatch({
          //   type: "FORM_ERROR",
          //   data: response?.response?.data?.data,
          //   payload: "error received",
          //   key: action.key,
          // });
        }
      },
      (error) => {
        console.log('error',error);

        // dispatch({
        //   type: "FORM_ERROR",
        //   payload: error?.response?.data?.data,
        //   key: action.key,
        // });
      }
    );
  };
}

export const sendViaEmail = (action)=>{
  return (dispatch) => {
    axios({
      url: api_base + "/admin/send_result_on_email",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            data: "",
            payload:response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            data: response?.response?.data,
            payload: "error received",
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data,
          key: action.key,
        });
      }
    );
  };
}

