import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
//import { calculateAge } from "../../../util";

//const stateKey = "idProoflist";

const RuleTypeDropDown = (props) => {
  const selectInput = useRef();
  
  const formik = props.validation;

  useEffect(() => {
    if (
      formik.value == null ||
      formik.value == undefined ||
      formik.value == ""
    ) {
      selectInput.current.clearValue();
    }
  }, [formik.value]);

  const dropdownOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'Insert', label: 'Insert' },
    { value: 'Update', label: 'Update' },
    { value: 'Delete', label: 'Delete' },
  ];

  return (
    <>
      <Select
        ref={selectInput}
        options={dropdownOptions}
        name={formik.name}
        onChange={(option) => {
          option !== null &&
            option !== undefined &&
            formik.form.setFieldValue(formik.name, option.value);
        }}
        isInvalid={!!formik.isInvalid}
        placeholder="Select Rule Type"
        onBlur={() => {
          formik.form.setFieldTouched(formik.name);
        }}
        value={
          dropdownOptions
            ? dropdownOptions.find((option) => {
                return option.value === formik.value;
              })
            : null
        }
        defaultValue={{ value: "", label: "Select Rule Type" }}
        className={
          formik.error &&
          formik.form.touched[formik.name] == true &&
          `is-invalid`
        }
        openMenuOnFocus={true}
      />
      {formik.form.touched[formik.name] == true && (
        <div className="field-error">{formik.error}</div>
      )}
    </>
  );
};

export default RuleTypeDropDown;
