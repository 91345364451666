import { useField } from "formik";
import { RadioGroup } from "@palmerhq/radio-group";
import "@palmerhq/radio-group/styles.css";

export default function CustomRadioGroup(props) {
  const [{ onChange, onBlur, ...field }] = useField(props.name);
  return (
    <RadioGroup
      {...props}
      {...field}
      labelledBy={props.name}
      onBlur={onBlur(props.name)}
      onChange={onChange(props.name)}
    />
  );
}
