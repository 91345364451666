import axios from "../Interceptor";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../Constants";

export const addClientDatabase = (action) => {
    return (dispatch) => {
        axios({
            url: api_base + "/admin/add_client_database",
            method: "post",
            data: action.payload,
        }).then(
            (response) => {
                if (response?.status == HTTP_OK) {
                dispatch({
                    type: "FORM_SUCCESS",
                    payload: response?.data?.data?.message,
                    key: action.key,
                });
                } else {
                dispatch({
                    type: "FORM_ERROR",
                    payload: response?.response?.data?.data,
                    moreError: response?.response?.data?.data,
                    key: action.key,
                });
                }
            },
            (error) => {
                dispatch({
                type: "FORM_ERROR",
                payload: error?.response?.data?.data,
                moreError: error?.response?.data?.data,
                key: action.key,
                });
            }
        );
    };
};