import * as Yup from "yup";

export const initial_values = { 
    driver:"",
    host:"",
    service_name:"",
    port:"",
    database_name:"",
    username:"",
    password:"",
    client_id:""
};
export const validation_Schema = Yup.object().shape({
    driver: Yup.string().required("*Please enter driver"),
    host: Yup.string().required("*Please enter host"),
    port: Yup.string().required("*Please enter port"),
    database_name: Yup.string().required("*Please enter database name"),
    username: Yup.string().required("*Please enter username"),
    password: Yup.string().required("*Please enter password"),
    client_id: Yup.string().required("*Please select client"),
    service_name:Yup.string().required("*Please enter service name")
}); 