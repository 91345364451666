import axios from "axios";

const instance = axios.create({});

const sess_data = sessionStorage.getItem("data");
const sess_json = JSON.parse(sess_data);

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Accept"] = "application/json";
// instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.common["Access-Control-Allow-Headers"] =
  "Origin, X-Requested-With,Content-Type,Accept";
sess_json?.token &&
  (instance.defaults.headers.common["Authorization"] =
    "Bearer " + sess_json?.token);

instance.interceptors.response.use(
  (response) => {
    sessionStorage.removeItem("serverError");
    return response;
  },
  (error) => {
    // if (error.response.status === 401) {
    //   sessionStorage.clear();
    //   sessionStorage.setItem(
    //     "serverError",
    //     JSON.stringify({
    //       heading: "Session Timed Out",
    //       text: "Your Session has expired. Please re-login to continue.",
    //     })
    //   );
    //   window.location.href = "/login";
    // }

    // if (error.response.status === 404) {
    //   sessionStorage.clear();
    //   sessionStorage.setItem(
    //     "serverError",
    //     JSON.stringify({
    //       heading: "API Failed",
    //       text: "Your Session has expired. Please re-login to continue.",
    //     })
    //   );
    //   window.location.href = "/login";
    // }
    // if (error.response.status === 500 || error.response.status === 429) {
    //   sessionStorage.clear();
    //   sessionStorage.setItem(
    //     "serverError",
    //     JSON.stringify({
    //       heading: "Server ERROR",
    //       text: "Your Session has expired. Please re-login to continue.",
    //     })
    //   );
    //   window.location.href = "/login";
    // }
    return error;
  }
);

export default instance;

export const multipart_instance = axios.create({});

multipart_instance.defaults.headers.common["Accept"] = "multipart/form-data";
multipart_instance.defaults.headers.common["Content-Type"] =
  "multipart/form-data";
multipart_instance.defaults.headers.common["Authorization"] =
  "Bearer " + sess_json?.token;

multipart_instance.interceptors.response.use(
  (response) => {
    sessionStorage.removeItem("serverError");
    return response;
  },
  (error) => {
    // if (error.response.status === 401) {
    //   sessionStorage.clear();
    //   sessionStorage.setItem(
    //     "serverError",
    //     JSON.stringify({
    //       heading: "Session Timed Out",
    //       text: "Your Session has expired. Please re-login to continue.",
    //     })
    //   );
    //   window.location.href = "/login";
    // }

    // if (error.response.status === 404) {
    //   sessionStorage.clear();
    //   sessionStorage.setItem(
    //     "serverError",
    //     JSON.stringify({
    //       heading: "API Failed",
    //       text: "Your Session has expired. Please re-login to continue.",
    //     })
    //   );
    //   window.location.href = "/login";
    // }

    // if (error.response.status === 500 || error.response.status === 429) {
    //   sessionStorage.clear();
    //   sessionStorage.setItem(
    //     "serverError",
    //     JSON.stringify({
    //       heading: "Server ERROR",
    //       text: "Your Session has expired. Please re-login to continue.",
    //     })
    //   );
    //   window.location.href = "/login";
    // }

    return error;
  }
);

export const wp_instance = axios.create({});
wp_instance.defaults.headers.common["Content-Type"] = "application/json";
wp_instance.defaults.headers.common["Accept"] = "application/json";
wp_instance.defaults.headers.common["Authorization"] = process.env.REACT_APP_WHATSAPP_API_TOKEN 
