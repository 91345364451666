import React,{ useEffect,useState } from "react";
import { connect } from "react-redux";
import { useFormik,Field,FormikProvider,FieldArray } from "formik";
import { addRule,checkRuleResult } from "../../redux/AllThunk/Rules";
import MasterLayout from "../../Layout/MasterLayout";
import { initial_values,validation_Schema,listObj } from "./addUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Form,Row,Table } from "react-bootstrap";
import RuleTypeDropdown from "../Common/RuleTypeDropdown";
import ClientListDropdown from "../Common/ClientListDropdown";

const stateKey="saveRule";
const ruleResultstateKey="checkRuleResult";

const Add = (props)=>{
    
    const [checkRule,saveCheckRule] = useState(false);
    const [resKeys,setResKeys] = useState([]);
    const [resValues,setRestValues] =useState([]);
    
    useEffect(()=>{
        props.dispatch({
            type: "RESET_FORM",
            key: ruleResultstateKey,
        });
    },[])

    const submitHandler = (values) => {

        if (formik.isValid === true) {
            if(checkRule===true){
                checkResult();
            }else{
                props.dispatch(
                    addRule({
                        payload: {
                            type: values.type,
                            statement: values.statement,
                            input_array:values.inputArray,
                            title:values.title,
                            client_id:values.client_id,
                        },
                        key: stateKey,
                    })
                );    
            }
        }
    };

    const formik = useFormik({
        initialValues: initial_values,
        values: initial_values,
        validationSchema: validation_Schema,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });


    useEffect(() => {
        //setLoading(false);
        if (props.formResult.success == true) {
            setTimeout(function () {
                props.dispatch({
                type: "RESET_FORM",
                key: stateKey,
                });
                alert("Rule Saved");
                formik.resetForm();
            }, 2000);
        }
    }, [props.formResult]);

    const checkResult = ()=>{
        if (formik.isValid === true) {
            props.dispatch(
                checkRuleResult({
                    payload: formik.values,
                    key: ruleResultstateKey,
                })
            );
        }
    }

    useEffect(()=>{
        let keyArr = [];
        let valueArr = [];
        
        props?.checkResult?.success==true &&  props?.checkResult?.data.length >0 &&
        props?.checkResult?.data.map((value,key)=>{
            let dataArr = [];
            value.map((v,k)=>{
                keyArr[key] = Object.keys(v);
                dataArr[k] = Object.values(v);
            })
            valueArr[key]= dataArr;
        })
        setResKeys(keyArr);
        setRestValues(valueArr);
    },[props?.checkResult])

    return (
        <MasterLayout>
            <div className="pagetitle">
            <h1>Add Rule</h1>
            {/* <nav>
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item">Forms</li>
                <li className="breadcrumb-item active">Elements</li>
                </ol>
            </nav> */}
            </div>
        
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                    <section className="section">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Select Client </Form.Label>
                                            <div className="col-sm-4">
                                            <Field name="client_id">
                                            {({ field, meta, form }) => {
                                                return (
                                                <ClientListDropdown
                                                    validation={{
                                                    ...field,
                                                    isInvalid: !!meta.error,
                                                    error: meta.error,
                                                    form: form,
                                                    }}
                                                />
                                                );
                                            }}
                                            </Field>
                                            </div>
                                        </Form.Group>      
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Rule Type</Form.Label>
                                            <div className="col-sm-4">
                                            <Field name="type">
                                            {({ field, meta, form }) => {
                                                return (
                                                <RuleTypeDropdown
                                                    validation={{
                                                    ...field,
                                                    isInvalid: !!meta.error,
                                                    error: meta.error,
                                                    form: form,
                                                    }}
                                                />
                                                );
                                            }}
                                            </Field>
                                            </div>
                                        </Form.Group>                                 

                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Title</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="title">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Rule Title"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                    
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Statement</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="statement">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    as="textarea"
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Sql Query"
                                                    
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="my-2">
                                        <Form.Label className="col-sm-2 col-form-label">Input Fields</Form.Label>

                                            <Row className="mb-3">
                                                <Form.Label className="col-3">Column Name </Form.Label>
                                                <Form.Label className="col-3">Type </Form.Label>
                                                <Form.Label className="col-3">Value </Form.Label>
                                                <Form.Label className="col-3">Description </Form.Label>
                                            </Row>
                                            <FieldArray
                                            name="inputArray"
                                            render={(arrayHelpers) => {
                                                const { push, remove, form } = arrayHelpers;
                                                const { values } = form;
                                                const row =
                                                values !== undefined
                                                    ? values.inputArray?.map((each, index) => {
                                                        return (
                                                        <div
                                                            key={`inputArray${index}`}
                                                            style={{ borderBottom: "2px solid #c1c1c1" }}
                                                        >
                                                            <Row className="d-flex flex-row-reverse">
                                                            <div
                                                                className="col-md-1"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {index > 0 && (
                                                                <>
                                                                    <FontAwesomeIcon
                                                                    icon={faCircleXmark}
                                                                    className="removeFieldIcon"
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                    />
                                                                </>
                                                                )}
                                                            </div>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Form.Group className="col-3">
                                                                    <Field
                                                                    name={`inputArray[${index}]['name']`}
                                                                    >
                                                                    {({ field, meta }) => (
                                                                        <>
                                                                        <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={!!meta.error && meta.touched}
                                                                            value={field.value ? field.value : ""}
                                                                        />
                                                                        {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                            {meta.error}
                                                                            </div>
                                                                        )}
                                                                        </>
                                                                    )}
                                                                    </Field>
                                                                </Form.Group>
                                                                <Form.Group className="col-3">
                                                                    <Field name={`inputArray.${index}.type`}>
                                                                    {({ field, meta }) => {
                                                                        return (
                                                                        <>
                                                                            <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={
                                                                                !!meta.error && meta.touched
                                                                            }
                                                                            value={field.value ? field.value : ""}
                                                                            />
                                                                            {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                                {meta.error}
                                                                            </div>
                                                                            )}
                                                                        </>
                                                                        );
                                                                    }}
                                                                    </Field>
                                                                </Form.Group>
                                                                <Form.Group className="col-3">
                                                                    <Field name={`inputArray.${index}.value`}>
                                                                    {({ field, meta }) => {
                                                                        return (
                                                                        <>
                                                                            <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={
                                                                                !!meta.error && meta.touched
                                                                            }
                                                                            value={field.value ? field.value : ""}
                                                                            />
                                                                            {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                                {meta.error}
                                                                            </div>
                                                                            )}
                                                                        </>
                                                                        );
                                                                    }}
                                                                    </Field>
                                                                </Form.Group>
                                                                <Form.Group className="col-3">
                                                                    <Field
                                                                    name={`inputArray[${index}]['description']`}
                                                                    >
                                                                    {({ field, meta }) => (
                                                                        <>
                                                                        <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={!!meta.error && meta.touched}
                                                                            value={field.value ? field.value : ""}
                                                                        />
                                                                        {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                            {meta.error}
                                                                            </div>
                                                                        )}
                                                                        </>
                                                                    )}
                                                                    </Field>
                                                                </Form.Group>
                                                            </Row>
                                                        </div>
                                                        );
                                                    })
                                                    : "";

                                                const result =
                                                values !== undefined ? (
                                                    <>
                                                    {row}
                                                    <div className="form-btns mt-4">
                                                        <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() => push(listObj)}
                                                        >
                                                        +
                                                        </button>
                                                    </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                );
                                                return result;
                                            }}
                                            ></FieldArray> 
                                        </Form.Group>                      
                                        <div className="row mb-3">
                                            {/* <label className="col-sm-2 col-form-label">Submit Button</label> */}
                                            <div className="col-6">
                                                <button type="submit" 
                                                className="btn btn-primary"
                                                onClick={()=>{
                                                    saveCheckRule(false);
                                                }}
                                                >Add Rule</button>
                                            </div>
                                            <div className="col-6">
                                                <button type="submit" 
                                                className="btn btn-success" 
                                                onClick={()=>{
                                                    saveCheckRule(true);
                                                }}
                                                >check Result</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Form>
            </FormikProvider>
            <div>
            Result :
            
            {props?.checkResult?.success==true &&  props?.checkResult?.data.length>0 && (
                <Table  responsive >
                {
                    resKeys.length>0 &&
                    resKeys.map((e,i)=>{ 

                    return (
                        <>
                        <thead  key={`th${i}`}>
                        <tr>
                        {e.map((v)=>{
                            return <th>{v}</th>
                        })}
                        </tr>
                        </thead>
                        <tbody key={`tbody${i}`}>
                        {resValues[i].map((value,k)=>{
                            const rw = value.map((v)=>{
                                return <td>{v}</td>
                            })
                            return <tr key={`tbody-r${k}`} >{rw}</tr>
                        })}
                        </tbody>
                        </>
                    )
                    })
                }
                </Table>
            )}
            
            {props?.checkResult?.error==true && (<div className="field-error">{props?.checkResult?.message}</div> )}
            </div>
        </MasterLayout>
    )
}
export default connect((state)=>{
    return { 
        formResult: { ...state?.Forms?.[stateKey] }, 
        checkResult: { ...state?.Forms?.[ruleResultstateKey] } 
    };
})(Add)