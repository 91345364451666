import React,{ useEffect,useState } from "react";
import { connect } from "react-redux";
import { useFormik,Field,FormikProvider,FieldArray } from "formik";
import { addClient } from "../../redux/AllThunk/Rules";
import MasterLayout from "../../Layout/MasterLayout";
import { initial_values,validation_Schema,listObj } from "./addUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Form,Row,Table } from "react-bootstrap";
import RuleTypeDropdown from "../Common/RuleTypeDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const stateKey="saveClientRule";
const ruleResultstateKey="addClientRuleResult";

const AddClient = (props)=>{
    
    const [resKeys,setResKeys] = useState([]);
    const [resValues,setRestValues] =useState([]);
    
    useEffect(()=>{
        props.dispatch({
            type: "RESET_FORM",
            key: ruleResultstateKey,
        });
    },[])

    const submitHandler = () => {
        console.log("Formik values >>>",formik);
        if (formik.isValid === true) {
            props.dispatch(
                addClient({
                    payload: {
                        name: formik.values.name,
                        email: formik.values.email,
                        mobile: formik.values.mobile,
                        dob: formik.values.dob,
                        city: formik.values.city,
                        state: formik.values.state,
                        region: formik.values.region,
                        segment: formik.values.segment,
                        sales_person: formik.values.sales_person,
                        password: formik.values.password,
                    },
                    key: stateKey,
                })
            );    
        }
    };

    const formik = useFormik({
        initialValues: initial_values,
        values: initial_values,
        validationSchema: validation_Schema,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });


    useEffect(() => {
        //setLoading(false);
        if (props.formResult.success == true) {
            setTimeout(function () {
                props.dispatch({
                type: "RESET_FORM",
                key: stateKey,
                });
                formik.resetForm();
            }, 2000);
        }
    }, [props.formResult]);

    useEffect(()=>{
        let keyArr = [];
        let valueArr = [];
        props?.formResult?.success==true && props?.formResult?.data != undefined && props?.formResult?.data.length >0 &&
        props?.formResult?.data.map((value,key)=>{
            let dataArr = [];
            value.map((v,k)=>{
                keyArr[key] = Object.keys(v);
                dataArr[k] = Object.values(v);
            })
            valueArr[key]= dataArr;
        })
        setResKeys(keyArr);
        setRestValues(valueArr);
    },[props?.formResult])

    return (
        <MasterLayout>
            <div className="pagetitle">
            <h1>Add Client</h1>
            {/* <nav>
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item">Forms</li>
                <li className="breadcrumb-item active">Elements</li>
                </ol>
            </nav> */}
            </div>
        
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                    <section className="section">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">                           

                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Name</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="name">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your name"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Email</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="email">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your Email"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Mobile</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="mobile">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your Mobile"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Date of birth</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="dob">
                                            {({ field, meta }) => (
                                                <>

                                                {/* <DatePicker
                                                    {...field}
                                                    selected={(field.value && new Date(field.value)) || null}
                                                    onChange={val => {
                                                        console.log("Field name and value",val);
                                                        // setFieldValue(field.name, val);
                                                    }}
                                                    /> */}
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your date of birth"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">City</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="city">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your city"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">State</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="state">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your state"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Region</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="region">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your region"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Segment</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="segment">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter your Segment"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Sales person</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="sales_person">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    // placeholder="Enter your name"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Password</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="password">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Enter password"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>                   
                                        <div className="row mb-3">
                                            {/* <label className="col-sm-2 col-form-label">Submit Button</label> */}
                                            <div className="col-6">
                                                <button type="submit" 
                                                className="btn btn-primary"
                                                >Add Client</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Form>
            </FormikProvider>
        </MasterLayout>
    )
}
export default connect((state)=>{
    return { 
        formResult: { ...state?.Forms?.[stateKey] }, 
        checkResult: { ...state?.Forms?.[ruleResultstateKey] } 
    };
})(AddClient)