import * as Yup from "yup";
import { notOnlyWhiteSpace } from "../../Constants";

export const listObj = {
    name:"",
    type:"",
    value:"",
    description:"",
};
export const initial_values = { type:"",title:"", statement:"",inputArray:[listObj], };
export const validation_Schema = Yup.object().shape({
    type: Yup.string().required("*Please select type"),
    title: Yup.string().required("*Please select title"),
    statement: Yup.string().required("*Please select statement"),
    inputArray: Yup.array().of(
        Yup.object().shape({
            name: Yup.string()
            //.required("*This is required")
            .min(1, "*This must have at least 1 characters")
            .max(100, "*This can't be longer than 100 characters")
            .matches(
              notOnlyWhiteSpace,
              "*Only Alphabets,Numbers and Spaces are allowed "
            ),
            type: Yup.string()
            //.required("*This is required")
            .min(2, "*This must have at least 2 characters")
            .max(100, "*This can't be longer than 100 characters")
            .matches(
              notOnlyWhiteSpace,
              "*Only Alphabets,Numbers and Spaces are allowed "
            ),
            value: Yup.string()
            //.required("*This is required")
            .min(1, "*This must have at least 1 characters")
            .max(100, "*This can't be longer than 100 characters")
            .matches(
              notOnlyWhiteSpace,
              "*Only Alphabets,Numbers and Spaces are allowed "
            ),
            description: Yup.string()
            //.required("*This is required")
            .min(2, "*This must have at least 2 characters")
            .max(300, "*This can't be longer than 300 characters")
            .matches(
              notOnlyWhiteSpace,
              "*Only Alphabets,Numbers and Spaces are allowed "
            ),
        })
    ),


});
