import * as Yup from "yup";

export const repeat_frequencyArr =['hourly','daily','weekly','monthly','yearly'];
export const weekDaysArr =['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
export const listObj = {
    name: "",
    type: "",
    description: "",
    value:"",

};
export const initial_values = { 
    rule_id: "", 
    client_id:"",
    inputArray: [],
    repeat_frequency:"daily",
    selected_week_days:[],
    selected_date:"",
    selected_time:"",
    monthly_type:"datewise",
    selected_month_day:[],
    send_via:[] 
};
export const validationSchema = Yup.object().shape({
  client_id: Yup.number().required("*Client is required")
  .typeError("A number is required"),
  rule_id: Yup.number().required("*Rule No. is required")
  .typeError("A number is required"),
  selected_time:Yup.string().required("*Cron Time is required"),
  send_via: Yup.array().min(1, "*Send Via is required"),

  //send_via:Yup.string().required("*Send Via is required"),

});
