import React from "react";
import  ReCAPTCHA  from "react-google-recaptcha";

const Captcha = (props)=>{
    return <ReCAPTCHA 
    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
    onChange={(v)=>{props.onChange(v)}}
    ref={props.reference}
    />
}
export default  Captcha