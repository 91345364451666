import React from "react";
import Header from "./Header";
import Footer from "./Footer";
//import { Container } from "react-bootstrap";
//import BurgerMenuPage from "../components/BurgerMenuPage";
import { useState } from "react";
import Sidebar from "./Sidebar";
//import WebFooter from "../components/WebFooter";

const MasterLayout = (props) => {
  //const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Header/>
      <Sidebar />
      <main id="main" className="main">
      {props.children}
      <Footer />
      </main>
    </>
  );
};

export default MasterLayout;
