import React, { useEffect,useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import MasterLayout from "../../Layout/MasterLayout";
import { couponTemplateList,deleteTemplate } from "../../redux/AllThunk/Coupon";
import DeleteConfirmation from "../Common/DeleteConfirmation";
//import { encrypt } from "../../Helper";
//import { Link } from "react-router-dom";

const ListKey = "coupontemplatelist";
const deleteKey = "deleteCouponTemplate";

const TemplateList = (props)=>{

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    useEffect(()=>{
        getTemplateList();
    },[])

    const proceedDelete = () => {
        setShowDeleteModal(false);

        props.dispatch(
            deleteTemplate({
                payload: { id: deleteId },
                key: deleteKey,
            })
        );
    };


    function getTemplateList(){
        props.dispatch(
            couponTemplateList({
                key: ListKey,
            })
        );
    }

    const cancelDelete = () => {
        setShowDeleteModal(false);
    };

    useEffect(() => {
        if (props.deleteTmp.success == true) {
            getTemplateList();
            alert("Coupon Template Deleted successfully");
            props.dispatch({
            type: "RESET_FORM",
            key: deleteKey,
            });
            setDeleteId(0);
            setShowDeleteModal(false);
        }
    }, [props.deleteTmp]);


    return (
        <MasterLayout>
        <DeleteConfirmation
        showConfirmModal={showDeleteModal}
        proceedSubmit={proceedDelete}
        cancelSubmit={cancelDelete}
        />
        <div className="pagetitle">
            <h1>Coupon Template List</h1>
        </div>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"></h5>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>Title</td>
                                        <td>Rule</td>
                                        <td>Fields</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.list?.success==true && props.list?.data.length>0 && 
                                        props.list?.data.map((value,key)=>{
                                            return <tr key={`tr${key}`}>
                                                <td>{key+1}</td>
                                                <td>{value.title}</td>
                                                <td>{value?.coupon_rule?.statement}</td>
                                                <td>
                                                    {/* {value?.input_array?.map((v,i)=>{
                                                    return (
                                                    <React.Fragment key={`d${i}`}>
                                                    <b>{v.name}</b>
                                                    <div>
                                                    {`${v.type}:${v.value !==undefined && v.value}:${v.description}\n`}
                                                    </div>
                                                    </React.Fragment>)
                                                })} */}
                                                </td>
                                                <td>
                                                    <button 
                                                    className="btn btn-danger"
                                                    onClick={()=>{
                                                        setDeleteId(value.id);
                                                        setShowDeleteModal(true);
                                                    }}>Delete</button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MasterLayout>
    )
}

export default connect((state)=>{
    return {
        list:{...state.GetData[ListKey]},
        deleteTmp:{...state.Forms[deleteKey]},
    }
})(TemplateList)
