export const login = `/login`;
export const dashboard = "/dashboard";
export const addRule = "/add-rule";
export const executeRule = `/execute-rule/:rule_id?`;
export const scheduleRule = "/schedule-rule/:rule_id?";
export const addClient = "/add-client";
export const ruleList = "/rule-list";
export const editRule = "/edit-rule/:rule_id";
export const createCouponTemplate = "/create-coupon-template";
export const couponTemplateList = "/coupon-template-list";
export const schedulerList = "/scheduler-list";
export const addClientDatabase = "/add-client-database";