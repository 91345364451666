import * as Yup from "yup";

export const listObj = {
    name: "",
    type: "",
    description: "",
    value:"",
};
export const initial_values = { 
  title:"",rule_id: "", inputArray: [listObj] 
};
export const validationSchema = Yup.object().shape({
  title: Yup.string().required("*Please select title"),
  rule_id: Yup.number().required("*Rule No. is required")
  .typeError("A number is required"),
});
