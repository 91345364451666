import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Login/index";
import Dashboard from "./Components/Dashboard";
import Auth from "./Components/Auth";
import AddRule from "./Components/Rules/Add";
import ExecuteRule from "./Components/Rules/Execute";
import { 
  login,
  dashboard,
  addRule,
  executeRule,
  scheduleRule,
  ruleList,
  editRule,
  createCouponTemplate,
  couponTemplateList,
  schedulerList,
  addClient,
  addClientDatabase,
} from "./route";
import Schedule from "./Components/Rules/Schedule";
import RuleList from "./Components/Rules/List";
import EditRule from "./Components/Rules/Edit";
import Private from "./Components/Private";
import CreateTemplate from "./Components/Coupon/CreateTemplate";
import TemplateList from "./Components/Coupon/TemplateList";
import SchedulerList from "./Components/Schedule/List";
import AddClient from "./Components/Client/AddClient";
import AddDatabase from "./Components/Client/AddDatabase";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
        path="/" 
        element={
          <Auth>
            <Login />
          </Auth>}
        />
        <Route
          path={login}
          element={
            <Auth>
              <Login />
            </Auth>
          }
        />
        <Route
          path={dashboard}
          element={
            <Private>
            <Dashboard />
            </Private>
          }
        />
        <Route
          path={addClient}
          element={
            <Private>
            <AddClient />
            </Private>
          }
        />
        <Route
          path={addClientDatabase}
          element={
            <Private>
            <AddDatabase />
            </Private>
          }
        />
        
        <Route
          path={addRule}
          element={
            <Private>
            <AddRule />
            </Private>
          }
        />
        <Route
          path={executeRule}
          element={
            <Private>
            <ExecuteRule />
            </Private>
          }
        />
        <Route
          path={scheduleRule}
          element={
            <Private>
            <Schedule />
            </Private>
          }
        />
        <Route
          path={ruleList}
          element={
            <Private>
            <RuleList />
            </Private>
          }
        />
        <Route
          path={editRule}
          element={
            <Private>
            <EditRule />
            </Private>
          }
        />
        <Route
          path={createCouponTemplate}
          element={
            <Private>
            <CreateTemplate />
            </Private>
          }
        />
        <Route
          path={couponTemplateList}
          element={
            <Private>
            <TemplateList />
            </Private>
          }
        />
        <Route
          path={schedulerList}
          element={
            <Private>
            <SchedulerList />
            </Private>
          }
        />



      </Routes>
    </BrowserRouter>
  );
}

export default App;
