import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";


const MonthDatesDropdown = (props) => {
    const selectInput = useRef();
    const list = [];

    for (let i=1;i<=31;i++){
        list.push({ value:String(i).padStart(2, '0') , label:String(i).padStart(2, '0')  }); 
    }

    const formik = props.validation;

    useEffect(() => {
        if (
            formik.value == null ||
            formik.value == undefined ||
            formik.value == ""
        ) {
            selectInput.current.clearValue();
        }
    }, [formik.value]);

  return (
    <>
      <Select
        ref={selectInput}
        options={list}
        name={formik.name}
        onChange={(option) => {
          option !== null &&
            option !== undefined &&
            formik.form.setFieldValue(formik.name, option.value);
        }}
        isInvalid={!!formik.isInvalid}
        placeholder="Select Any"
        onBlur={() => {
          formik.form.setFieldTouched(formik.name);
        }}
        value={
            list
            ? list.find((option) => {
                return option.value === formik.value;
              })
            : null
        }
        defaultValue={{ value: "", label: "Select Any " }}
        className={
          formik.error &&
          formik.form.touched[formik.name] == true &&
          `is-invalid`
        }
        openMenuOnFocus={true}
      />
      {formik.form.touched[formik.name] == true && (
        <div className="field-error">{formik.error}</div>
      )}
    </>
  );
};

export default MonthDatesDropdown;