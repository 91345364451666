import { Navigate } from "react-router-dom";

export default function Private({ children }) {
    const sess_data = sessionStorage.getItem("data");
    const sessionData = JSON.parse(sess_data);
    if (sessionData == null || sessionData == undefined){
        return <Navigate to="/" replace />;
    }
    return children;
}
