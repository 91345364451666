import { connect } from "react-redux";
import { useEffect,useState } from "react";
import { Form,Row,Table } from "react-bootstrap";
import MasterLayout from "../../Layout/MasterLayout";
import { initial_values,validationSchema,listObj } from "./exeUtil";
import {ExecuteRule,ruleList,getRuleDetail} from "../../redux/AllThunk/Rules";
import { useFormik,FormikProvider,Field,FieldArray } from "formik";
import RuleListDropdown from "../Common/RuleListDropdown";
import { useParams } from "react-router-dom";
import { decrypt } from "../../Helper";

const stateKey = "executeRule";
const ruleListKey = "ruleslist";

const Execute  = (props)=>{

    const [selectedRule,setSelectedRule] = useState("");
    const [initialValues,setInitialValues] = useState(initial_values);
    const [resKeys,setResKeys] = useState([]);
    const [resValues,setRestValues] =useState([]);
    const { rule_id } = useParams();

    useEffect(()=>{
        props.dispatch(
            ruleList({
                key: ruleListKey,
            })
        );
        
        if(rule_id!==undefined){
            const ruleid =     decrypt(rule_id);
            setInitialValues({...initialValues,rule_id:ruleid})
        }
    },[])


    const submitHandler = (values) => {
        // console.log("Values are >>",values);
        if (formik.isValid === true) {
            let inputKeys =  [];
            let inputValues = [];

            values.inputArray.map((e,i)=>{
                inputKeys.push(e.name);
                inputValues.push(e.value);
            })
            props.dispatch(
                ExecuteRule({
                    payload: {
                        rule_id: values.rule_id,
                        inputKeys: inputKeys,
                        inputValues: inputValues,
                        client_id: values.client_id
                    },
                    key: stateKey,
                })
            );
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        values: initial_values,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });

    useEffect(() => {
        console.log("value is >>",props?.list?.data?.length > 0 && props?.list?.data);
        let abc = 
        props?.list?.data?.length > 0 ?
            props?.list?.data.filter((each) => {
                if (each.id == formik.values.rule_id) {
                    return true;
                }
                return false;
            }):{};
        abc.length>0 && setSelectedRule(abc[0]);
    }, [formik.values.rule_id]);


    useEffect(()=>{
        console.log("selectedRule is >>",selectedRule);
        let newArr = (selectedRule.input_array) ?
        selectedRule.input_array.length>0?
        selectedRule.input_array.map((v,i)=>{
            return {...v,value:""}
        }):[]:[];
        selectedRule && setInitialValues({...formik.values,inputArray:newArr});
       
        setInitialValues({...formik.values,client_id:selectedRule.client_id})
    },[selectedRule])


    useEffect(()=>{
        let keyArr = [];
        let valueArr = [];
        
        props?.formResult?.success==true &&  props?.formResult?.data.length >0 &&
        props?.formResult?.data.map((value,key)=>{
            let dataArr = [];
            value.map((v,k)=>{
                keyArr[key] = Object.keys(v);
                dataArr[k] = Object.values(v);
            })
            valueArr[key]= dataArr;
        })
        setResKeys(keyArr);
        setRestValues(valueArr);
    },[props?.formResult])

    return (
    <MasterLayout>
        <div className="pagetitle">
            <h1>Execute Rule</h1>
        </div>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"></h5>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                                <Field
                                name="client_id"
                                >
                                {({ field, meta }) => (
                                    <>
                                    <Form.Control
                                        {...field}
                                        autoComplete="off"
                                        isInvalid={!!meta.error && meta.touched}
                                        value={field.value ? field.value : ""}
                                        type="hidden"
                                    />
                                    </>
                                )}
                                </Field>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-2 col-form-label">Select Rule </Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="rule_id">
                                    {({ field, meta, form }) => {
                                        return (
                                        <RuleListDropdown
                                            validation={{
                                            ...field,
                                            isInvalid: !!meta.error,
                                            error: meta.error,
                                            form: form,
                                            }}
                                        />
                                        );
                                    }}
                                    </Field>
                                    </div>
                                </Form.Group>

                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-2">Statement: </Form.Label>
                                    <div  className="col-sm-10">
                                        {selectedRule && selectedRule.statement}
                                    </div>
                                </Form.Group>
                                <Form.Group className="row my-2">
                                    <Form.Label className="col-sm-2 col-form-label">Input Fields:</Form.Label>
                                    <Row className="mb-3">
                                        <Form.Label className="col-sm-3">Name </Form.Label>
                                        <Form.Label className="col-sm-3">Type </Form.Label>
                                        <Form.Label className="col-sm-3">Value </Form.Label>
                                        <Form.Label className="col-sm-3">Description </Form.Label>
                                    </Row>
                                    
                                    <FieldArray
                                    name="inputArray"
                                    render={(arrayHelpers) => {
                                        const { push,form } = arrayHelpers;
                                        const { values } = form;
                                        const row =
                                        values !== undefined
                                            ? values.inputArray?.map((each, index) => {
                                                return (
                                                <div
                                                    key={`inputArray${index}`}
                                                    style={{ borderBottom: "2px solid #c1c1c1" }}
                                                    className="mb-2"
                                                >
                                                <Row>
                                                    <Form.Group className="col-md-3">
                                                        <Field
                                                        name={`inputArray[${index}]['name']`}
                                                        >
                                                        {({ field, meta }) => (
                                                            <>
                                                            <Form.Control
                                                                {...field}
                                                                autoComplete="off"
                                                                isInvalid={!!meta.error && meta.touched}
                                                                value={field.value ? field.value : ""}
                                                            />
                                                            {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                {meta.error}
                                                                </div>
                                                            )}
                                                            </>
                                                        )}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3">
                                                        <Field name={`inputArray.${index}.type`}>
                                                        {({ field, meta }) => {
                                                            return (
                                                            <>
                                                                <Form.Control
                                                                {...field}
                                                                autoComplete="off"
                                                                isInvalid={
                                                                    !!meta.error && meta.touched
                                                                }
                                                                value={field.value ? field.value : ""}
                                                                />
                                                                {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                    {meta.error}
                                                                </div>
                                                                )}
                                                            </>
                                                            );
                                                        }}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3">
                                                        <Field
                                                        name={`inputArray[${index}]['value']`}
                                                        >
                                                        {({ field, meta }) => (
                                                            <>
                                                            <Form.Control
                                                                {...field}
                                                                autoComplete="off"
                                                                isInvalid={!!meta.error && meta.touched}
                                                                value={field.value ? field.value : ""}
                                                            />
                                                            {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                {meta.error}
                                                                </div>
                                                            )}
                                                            </>
                                                        )}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3">
                                                        <Field
                                                        name={`inputArray[${index}]['description']`}
                                                        >
                                                        {({ field, meta }) => (
                                                            <>
                                                            <Form.Control
                                                                {...field}
                                                                as="textarea"
                                                                autoComplete="off"
                                                                isInvalid={!!meta.error && meta.touched}
                                                                value={field.value ? field.value : ""}
                                                            />
                                                            {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                {meta.error}
                                                                </div>
                                                            )}
                                                            </>
                                                        )}
                                                        </Field>
                                                    </Form.Group>
                                                </Row>
                                                </div>
                                                );
                                            })
                                            : "";

                                            const result =
                                            values !== undefined ? <>{row}</>: <></>;
                                        return result;
                                    }}
                                    ></FieldArray> 
                                    
                                </Form.Group>  
                    
                                <div className="col-6 text-center">
                                <button className="btn btn-primary w-100" type="submit" >Execute</button>
                                </div>
                                
                                </Form>
                            </FormikProvider>
                            <div>Result</div>

                            {props?.formResult?.success==true &&  props?.formResult?.data.length>0 && (
                            <Table  responsive >
                            {
                                resKeys.length>0 &&
                                resKeys.map((e,i)=>{ 

                                return (
                                    <>
                                    <thead key={`thead${i}`}>
                                    <tr>
                                    {e.map((v,indx)=>{
                                        return <th key={`th${indx}`} >{v}</th>
                                    })}
                                    </tr>
                                    </thead>
                                    <tbody key={`tbody${i}`}>
                                    {resValues[i].map((value,k)=>{
                                        const rw = value.map((v,ind)=>{
                                            return <td key={`td${ind}`}>{v}</td>
                                        })
                                        return <tr key={`tbody-r${k}`} >{rw}</tr>
                                    })}
                                    </tbody>
                                    </>
                                )
                                })
                            }
                            </Table>
                            )}
                            {props?.formResult?.error==true && (<div className="field-error">{props?.formResult?.message}</div> )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MasterLayout>
    )
}

export default connect((state)=>{
    return { 
        formResult: { ...state?.Forms?.[stateKey] },
        list: { ...state.GetData[ruleListKey] },
    };
})(Execute);