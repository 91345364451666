import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

const MonthDaysDropdown = (props) => {
  const selectInput = useRef();
    const month_dayArr = [
        { value: 'Select', label: 'Select' },
        { value: 'first_day_of_month', label: 'First day of month' },
        { value: 'last_day_of_month', label: 'Last day of month' },
        { value: 'first_sunday', label: 'First Sunday' },
        { value: 'last_sunday', label: 'Last Sunday' },
    ];

  const formik = props.validation;

  useEffect(() => {
    if (
      formik.value == null ||
      formik.value == undefined ||
      formik.value == ""
    ) {
      selectInput.current.clearValue();
    }
  }, [formik.value]);

  return (
    <>
      <Select
        ref={selectInput}
        options={month_dayArr}
        name={formik.name}
        onChange={(option) => {
          option !== null &&
            option !== undefined &&
            formik.form.setFieldValue(formik.name, option.value);
        }}
        isInvalid={!!formik.isInvalid}
        placeholder="Select Any"
        onBlur={() => {
          formik.form.setFieldTouched(formik.name);
        }}
        value={
            month_dayArr
            ? month_dayArr.find((option) => {
                return option.value === formik.value;
              })
            : null
        }
        defaultValue={{ value: "", label: "Select Any " }}
        className={
          formik.error &&
          formik.form.touched[formik.name] == true &&
          `is-invalid`
        }
        openMenuOnFocus={true}
      />
      {formik.form.touched[formik.name] == true && (
        <div className="field-error">{formik.error}</div>
      )}
    </>
  );
};

export default MonthDaysDropdown;