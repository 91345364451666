const Forms = function (
    state = {
      Login: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      Logout: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      saveRule: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      saveClientRule: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      executeRule: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      emailRuleResult: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      whatsappRuleResult: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      checkRuleResult: {
        loading: true,
        error: false,
        success: false,
        message: "",
        data:"",
      },
      addClientRuleResult: {
        loading: true,
        error: false,
        success: false,
        message: "",
        data:"",
      },
      deleteRule: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },
      updateRule: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },
      validateCaptcha:false,
      createCouponTemplate: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },
      deleteCouponTemplate: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },
      deleteScheduler: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },
      saveClientDatabase: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },
    },
    action
  ) {
    switch (action.type) {
      case "RESET_FORM": {
        state = { ...state };
        state[action.key]["loading"] = true;
        state[action.key]["success"] = false;
        state[action.key]["error"] = false;
        state[action.key]["message"] = "";
        return state;
      }
      case "FORM_SUCCESS": {
        state = { ...state };
        state[action.key]["success"] = true;
        state[action.key]["error"] = false;
        state[action.key]["message"] = action.payload;
        state[action.key]["loading"] = false;
        state[action.key]["data"] = action?.data;
        return state;
      }
      case "FORM_ERROR": {
        state = { ...state };
        state[action.key]["success"] = false;
        state[action.key]["error"] = true;
        state[action.key]["message"] = action.payload;
        state[action.key]["loading"] = false;
        return state;
      }
      default:
        return state;
    }
  };
  
export default Forms;
  