import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

const stateKey = "clientlist";

const ClientListDropDown = (props) => {
  const selectInput = useRef();
  const [dropdownOptions, setDropDownOptions] = useState([]);

  const formik = props.validation;

  useEffect(() => {
    if (
      formik.value == null ||
      formik.value == undefined ||
      formik.value == ""
    ) {
      selectInput.current.clearValue();
    }
  }, [formik.value]);

  useEffect(() => {
    let options =
      props?.list?.data?.length > 0
        ? props.list.data.map((each) => {
            return { value: each.id, label: each.name };
          })
        : [];

    setDropDownOptions(options);
  }, [props.list]);

  return (
    <>
      <Select
        ref={selectInput}
        options={dropdownOptions}
        name={formik.name}
        // onChange={(option) => {
        //   option !== null &&
        //     option !== undefined &&
        //     formik.form.setFieldValue(formik.name, option.value);
        // }}
        onChange={(option) => {
            formik?.form?.setFieldValue(formik?.name, option?.value);
        }}
        isInvalid={!!formik.isInvalid}
        placeholder="Select Client"
        onBlur={() => {
          formik.form.setFieldTouched(formik.name);
        }}
        value={
          dropdownOptions
            ? dropdownOptions.find((option) => {
                return option.value === formik.value;
              })
            : null
        }
        defaultValue={{ value: "", label: "Select Client " }}
        className={
          formik.error &&
          formik.form.touched[formik.name] == true &&
          `is-invalid`
        }
        openMenuOnFocus={true}
      />
      {formik.form.touched[formik.name] == true && (
        <div className="field-error">{formik.error}</div>
      )}
    </>
  );
};

export default connect((state) => {
  return {
    list: { ...state.GetData[stateKey] },
  };
})(ClientListDropDown);
