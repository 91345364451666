import axios from "../Interceptor";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../Constants";

export const createCouponTemplate = (action) => {
    return (dispatch) => {
      axios({
        url: api_base + "/admin/create_coupon_template",
        method: "post",
        data: action.payload,
      }).then(
        (response) => {
          if (response?.status == HTTP_OK) {
            dispatch({
              type: "FORM_SUCCESS",
              payload: response?.data?.data?.message,
              key: action.key,
            });
          } else {
            dispatch({
              type: "FORM_ERROR",
              payload: response?.response?.data?.data,
              moreError: response?.response?.data?.data,
              key: action.key,
            });
          }
        },
        (error) => {
          dispatch({
            type: "FORM_ERROR",
            payload: error?.response?.data?.data,
            moreError: error?.response?.data?.data,
            key: action.key,
          });
        }
      );
    };
};

export const deleteTemplate = (action)=>{
  return (dispatch) => {
    axios({
      url: api_base + "/admin/delete_coupon_template",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data?.message,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data?.data,
            moreError: response?.response?.data?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data?.data,
          moreError: error?.response?.data?.data,
          key: action.key,
        });
      }
    );
  };
}

export const couponTemplateList = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/admin/coupon_template_list",
      method: "get",
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "GET_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "GET_ERROR",
            payload: response?.response?.data?.data,
            key: action.key,
          });
        }
      },
      (error) => {
          dispatch({
              type: "GET_ERROR",
              payload: error?.response?.data?.data,
              key: action.key,
          });
      }
    );
  };
};