import { Navigate } from "react-router-dom";
import { dashboard } from '../route';

export default function Auth({ children }) {
  const sess_data = sessionStorage.getItem("data");
  const sessionData = JSON.parse(sess_data);
  if (sessionData) {
    return <Navigate to={dashboard} replace />;
  }
  return children;
}
