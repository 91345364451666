import { connect } from "react-redux";
import { useEffect,useState } from "react";
import { Form,Row } from "react-bootstrap";
import MasterLayout from "../../Layout/MasterLayout";
import { initial_values,
    validationSchema,
    repeat_frequencyArr,
    weekDaysArr } from "./scheduleUtil"
import {ExecuteRule,ruleList,scheduleRule,
    clientList,sendViaEmail,
    sendViaWhatsapp} from "../../redux/AllThunk/Rules";
import { useFormik,FormikProvider,Field,FieldArray } from "formik";
import RuleListDropdown from "../Common/RuleListDropdown";
//import { stringFromObj } from "../../Helper";
import ClientListDropdown from "../Common/ClientListDropdown";
import {Whatsapp_msg_template,dateString} from "../../Helper";
import { Radio } from '@palmerhq/radio-group';  
import CustomRadioGroup from "../Common/CustomRadioGroup";
import MonthDaysDropdown from "../Common/MonthDaysDropdown";
import MonthDatesDropdown from "../Common/MonthDatesDropdown";
import { useParams } from "react-router-dom";
import { decrypt } from "../../Helper";


const stateKey = "executeRule";
const emailResultKey = "emailRuleResult";
const whatsappResultKey = "whatsappRuleResult";
const ruleListKey = "ruleslist";
const clientListKey = "clientlist";

const Schedule  = (props)=>{

    const [selectedRule,setSelectedRule] = useState();
    const [initialValues,setInitialValues] = useState(initial_values);
    const [successResult,setSuccessResult] = useState("");
    const [runCronBtnState,setRunCronBtnState] = useState(false);
    const { rule_id } = useParams();

    useEffect(()=>{
        props.dispatch(
            ruleList({
                key: ruleListKey,
            })
        );

        props.dispatch(
            clientList({
                payload: {},
                key: clientListKey,
            })
        );

        if(rule_id!==undefined){
            const ruleid =  decrypt(rule_id);
            setInitialValues({...initialValues,rule_id:ruleid})
        }
    },[])

    const submitHandler = (values) => {    
        if (formik.isValid === true) {
            let inputKeys =  [];
            let inputValues = [];

            values.inputArray.map((e,i)=>{
                inputKeys.push(e.name);
                inputValues.push(e.value);
            })
            props.dispatch(
                scheduleRule({
                    payload: {...values,
                        inputKeys: inputKeys,
                        inputValues: inputValues,
                    },
                    key: stateKey,
                })
            );
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        values: initial_values,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });

    useEffect(() => {
        let abc = 
        props?.list?.data?.length > 0 ?
            props?.list?.data.filter((each) => {
                if (each.id == formik.values.rule_id) {
                    return true;
                }
                return false;
            }):{};
            setSelectedRule(abc[0]);
    }, [formik.values.rule_id]);


    useEffect(()=>{
        setSuccessResult("");
        let newArr = selectedRule?.input_array ?
        selectedRule.input_array.length>0?
        selectedRule.input_array.map((v,i)=>{
            return {...v,value:""}
        }):[]:[];
        selectedRule && setInitialValues({...formik.values,inputArray:newArr});
    },[selectedRule])

    useEffect(()=>{
        if(props.formResult.success==true)
        {
            formik.resetForm();
            setInitialValues(initial_values)

            formik.values = initial_values;
            formik.initialValues = initial_values;
            setRunCronBtnState(false);
        }
       },[props.formResult])

    const executeRule = ()=>{
        if(formik.isValid === true) {
            let inputKeys =  [];
            let inputValues = [];

            formik.values.inputArray.map((e)=>{
                inputKeys.push(e.name);
                inputValues.push(e.value);
            })
            props.dispatch(
                ExecuteRule({
                    payload: {
                        rule_id: formik.values.rule_id,
                        client_id:formik.values.client_id,
                        send_via:formik.values.send_via[0],
                        inputKeys: inputKeys,
                        inputValues: inputValues,
                    },
                    key: stateKey,
                })
            );
            setRunCronBtnState(true);
        }
    }

    const sendOnWhatsapp = ()=>{
        if(formik.isValid === true) {

            const template = Whatsapp_msg_template({ 
                filepath: "abc.pdf",
                receiver:"917470560225",
                catalogName:"abc",
                filename:"abc" });

            props.dispatch(
                sendViaWhatsapp({
                payload:template,
                key: whatsappResultKey,
                })
            );
        }
    }
    
    const sendOnEmail =()=>{
        if(formik.isValid === true) {
            let inputKeys =  [];
            let inputValues = [];

            formik.values.inputArray.map((e)=>{
                inputKeys.push(e.name);
                inputValues.push(e.value);
            })
            props.dispatch(
                sendViaEmail({
                    payload: {
                        rule_id: formik.values.rule_id,
                        client_id:formik.values.client_id,
                        inputKeys: inputKeys,
                        inputValues: inputValues,
                    },
                    key: emailResultKey,
                })
            );
        }   
    }
   
    return (
    <>
    <MasterLayout>
        <div className="pagetitle">
            <h1>Schedule Rule</h1>
        </div>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"></h5>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-2 col-form-label">Select Client </Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="client_id">
                                    {({ field, meta, form }) => {
                                        return (
                                        <ClientListDropdown
                                            validation={{
                                            ...field,
                                            isInvalid: !!meta.error,
                                            error: meta.error,
                                            form: form,
                                            }}
                                        />
                                        );
                                    }}
                                    </Field>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-2 col-form-label">Select Rule </Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="rule_id">
                                    {({ field, meta, form }) => {
                                        return (
                                        <RuleListDropdown
                                            validation={{
                                            ...field,
                                            isInvalid: !!meta.error,
                                            error: meta.error,
                                            form: form,
                                            }}
                                        />
                                        );
                                    }}
                                    </Field>
                                    </div>
                                </Form.Group>

                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-2">Statement: </Form.Label>
                                    <div  className="col-sm-10">
                                        {selectedRule && selectedRule.statement}
                                    </div>
                                   
                                </Form.Group>
                                
                                <Form.Group className="row my-2">
                                    <Form.Label className="col-sm-2 col-form-label">Input Fields:</Form.Label>

                                    <Row className="mb-3">
                                        <Form.Label className="col-sm-3">Name </Form.Label>
                                        <Form.Label className="col-sm-3">Type </Form.Label>
                                        <Form.Label className="col-sm-3">Value </Form.Label>
                                        <Form.Label className="col-sm-3">Description </Form.Label>
                                    </Row>
                                    
                                    <FieldArray
                                    name="inputArray"
                                    render={(arrayHelpers) => {
                                        const { push,form } = arrayHelpers;
                                        const { values } = form;
                                        const row =
                                        values !== undefined
                                            ? values.inputArray?.map((each, index) => {
                                                return (
                                                <div
                                                    key={`inputArray${index}`}
                                                    style={{ borderBottom: "2px solid #c1c1c1" }}
                                                    className="mb-2"
                                                >
                                                <Row>
                                                    <Form.Group className="col-md-3">
                                                        <Field
                                                        name={`inputArray[${index}]['name']`}
                                                        >
                                                        {({ field, meta }) => (
                                                            <>
                                                            <Form.Control
                                                                {...field}
                                                                autoComplete="off"
                                                                isInvalid={!!meta.error && meta.touched}
                                                                value={field.value ? field.value : ""}
                                                            />
                                                            {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                {meta.error}
                                                                </div>
                                                            )}
                                                            </>
                                                        )}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3">
                                                        <Field name={`inputArray.${index}.type`}>
                                                        {({ field, meta }) => {
                                                            return (
                                                            <>
                                                                <Form.Control
                                                                {...field}
                                                                autoComplete="off"
                                                                isInvalid={
                                                                    !!meta.error && meta.touched
                                                                }
                                                                value={field.value ? field.value : ""}
                                                                />
                                                                {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                    {meta.error}
                                                                </div>
                                                                )}
                                                            </>
                                                            );
                                                        }}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3">
                                                        <Field
                                                        name={`inputArray[${index}]['value']`}
                                                        >
                                                        {({ field, meta }) => (
                                                            <>
                                                            <Form.Control
                                                                {...field}
                                                                autoComplete="off"
                                                                isInvalid={!!meta.error && meta.touched}
                                                                value={field.value ? field.value : ""}
                                                            />
                                                            {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                {meta.error}
                                                                </div>
                                                            )}
                                                            </>
                                                        )}
                                                        </Field>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3">
                                                        <Field
                                                        name={`inputArray[${index}]['description']`}
                                                        >
                                                        {({ field, meta }) => (
                                                            <>
                                                            <Form.Control
                                                                {...field}
                                                                as="textarea"
                                                                autoComplete="off"
                                                                isInvalid={!!meta.error && meta.touched}
                                                                value={field.value ? field.value : ""}
                                                            />
                                                            {!!meta.error && meta.touched && (
                                                                <div className="field-error">
                                                                {meta.error}
                                                                </div>
                                                            )}
                                                            </>
                                                        )}
                                                        </Field>
                                                    </Form.Group>
                                                </Row>
                                                </div>
                                                );
                                            })
                                            : "";

                                            const result =
                                            values !== undefined ? <>{row}</>: <></>;
                                        return result;
                                    }}
                                    ></FieldArray> 
                                    
                                </Form.Group> 
                                <Form.Group className="row my-2">
                                    <div className="col-sm-12">
                                        <Form.Label className="col-sm-4 col-form-label">Repeat Frequency </Form.Label>
                                        <CustomRadioGroup name="repeat_frequency" autoFocus={false}>
                                            {repeat_frequencyArr.map((each, index) => {
                                                return (
                                                <Radio key={`option${index}`} value={each}>
                                                    {each}
                                                </Radio>
                                                );
                                            })}
                                        </CustomRadioGroup>
                                    </div>
                                </Form.Group>
                                {formik.values.repeat_frequency=="weekly" && (
                                    <Form.Group className="row my-2">
                                        <Form.Label className="col-sm-4 col-form-label">Select Week Days </Form.Label>
                                        <div className="col-sm-12 d-lg-flex">
                                            {weekDaysArr.map((each,index)=>{
                                                return (
                                                    <Field name="selected_week_days" key={`wd${index}`}>
                                                    {({ field, meta }) => {
                                                        return (
                                                        <div className="col-1 d-flex me-2">

                                                        <Form.Control
                                                            {...field}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            isInvalid={!!meta.error && meta.touched}
                                                            value={each}
                                                        />{each}
                                                        
                                                        {!!meta.error && meta.touched && (
                                                            <div className="field-error">
                                                            {meta.error}
                                                            </div>
                                                        )}
                                                        </div>
                                                        );
                                                    }}
                                                    </Field>
                                                );
                                            })}

                                        


                                        </div>
                                    </Form.Group>
                                )}
                                {formik.values.repeat_frequency=="monthly" && (
                                    <div className="row d-flex my-2">
                                        <Form.Group className="col-5">
                                            <Form.Label className="col-form-label">Monthly Type </Form.Label>
                                            <CustomRadioGroup name="monthly_type" autoFocus={false}>
                                                <Radio key={`mtdate`} value="datewise">
                                                Date Wise</Radio>
                                                <Radio key={`mtday`} value="daywise">
                                                Day Wise</Radio>
                                            </CustomRadioGroup>
                                        </Form.Group>
                                        {formik.values.monthly_type=="datewise" && (
                                            <Form.Group className="col-3">
                                                <Form.Label className="col-form-label">Select Date </Form.Label>
                                                <Field name="selected_date">
                                                {({ field, meta, form }) => {
                                                    return (
                                                    <MonthDatesDropdown
                                                        validation={{
                                                        ...field,
                                                        isInvalid: !!meta.error,
                                                        error: meta.error,
                                                        form: form,
                                                        }}
                                                    />
                                                    );
                                                }}
                                                </Field>
                                            </Form.Group>
                                        )}
                                        {formik.values.monthly_type=="daywise" && (
                                            <Form.Group className="col-6">
                                                <Form.Label className="col-form-label">Select Date </Form.Label>
                                                <Field name="selected_month_day">
                                                {({ field, meta, form }) => {
                                                    return (
                                                    <MonthDaysDropdown
                                                        validation={{
                                                        ...field,
                                                        isInvalid: !!meta.error,
                                                        error: meta.error,
                                                        form: form,
                                                        }}
                                                    />
                                                    );
                                                }}
                                                </Field>
                                            </Form.Group>
                                        )}
                                    </div>
                                )}
                                {/* disable past date selection in hourly option */}
                                <Form.Group className="row my-2">
                                    {formik.values.repeat_frequency =='yearly' ||
                                    formik.values.repeat_frequency =='hourly' && (
                                        <div className="col-4">
                                            <Form.Label className="col-form-label">Start Date </Form.Label>
                                            <Field name="selected_date">
                                            {({ field, meta,  }) => {
                                                const today = dateString();
                                                return (
                                                    <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    type="date"
                                                    isInvalid={!!meta.error && meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    min={today}
                                                />
                                                {!!meta.error && meta.touched && (
                                                    <div className="field-error">
                                                    {meta.error}
                                                    </div>
                                                )}
                                                </>
                                                );
                                            }}
                                            </Field>
                                        </div>
                                    )}
                                    <div className="col-4">
                                        <Form.Label className="col-form-label">Select Time </Form.Label>
                                        <Field name="selected_time">
                                        {({ field, meta}) => {
                                            return (
                                                <>
                                            <Form.Control
                                                {...field}
                                                autoComplete="off"
                                                type="time"
                                                isInvalid={!!meta.error && meta.touched}
                                                value={field.value ? field.value : ""}
                                            />
                                            {!!meta.error && meta.touched && (
                                                <div className="field-error">
                                                {meta.error}
                                                </div>
                                            )}
                                            </>
                                            );
                                        }}
                                        </Field>
                                    </div>
                                </Form.Group>

                                



                                { /*
                                <Form.Group className="row my-2">
                                    <Form.Label className="col-sm-2 col-form-label">Every </Form.Label>
                                    <div className="col-sm-2">
                                    <Field name="days">
                                    {({ field, meta, form }) => {
                                        return (
                                            <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={!!meta.error && meta.touched}
                                            value={field.value ? field.value : ""}
                                        />
                                        {!!meta.error && meta.touched && (
                                            <div className="field-error">
                                            {meta.error}
                                            </div>
                                        )}
                                        </>
                                        );
                                    }}
                                    </Field>Days
                                    </div>
                                    <div className="col-sm-2">
                                    <Field name="time">
                                    {({ field, meta,  }) => {
                                        return (
                                            <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            type="time"
                                            isInvalid={!!meta.error && meta.touched}
                                            value={field.value ? field.value : ""}
                                        />
                                        {!!meta.error && meta.touched && (
                                            <div className="field-error">
                                            {meta.error}
                                            </div>
                                        )}
                                        </>
                                        );
                                    }}
                                    </Field>Time
                                    </div>

                                </Form.Group> 
                                */ }
                                 

                                <Form.Group className="row my-2 d-flex flex-column">
                                    <div>
                                        <Form.Label className="col-sm-3 col-form-label">Send result Via </Form.Label>
                                        <div className="col-sm-2 d-flex">
                                        <Field name="send_via">
                                        {({ field, meta }) => {
                                            return (
                                            <Form.Control
                                                {...field}
                                                className="form-check-input"
                                                type="checkbox"
                                                //isInvalid={!!meta.error && meta.touched}
                                                value="whatsapp"
                                            />
                                            );
                                        }}
                                        </Field>Whatsapp
                                        </div>
                                        <div className="col-sm-2 d-flex">
                                        <Field name="send_via">
                                        {({ field, meta }) => {
                                            return (
                                            <Form.Control
                                                {...field}
                                                type="checkbox"
                                                className="form-check-input"
                                                //isInvalid={!!meta.error && meta.touched}
                                                value="gmail"
                                            />
                                            );
                                        }}
                                        </Field>Email
                                        </div>
                                    </div>
                                    {!!formik.errors.send_via &&  (
                                        <div className="field-error">
                                        {formik.errors.send_via}
                                        </div>
                                    )}
                                </Form.Group> 
                    
                                <div className="col-3 text-center">
                                <button className="btn btn-primary w-100" type="button" onClick={()=>{ executeRule(); }} disabled={runCronBtnState} >Run Cron Now</button>
                                </div>
                                <div className="col-3 text-center">
                                <button className="btn btn-primary w-100" type="submit" >Save Cron</button>
                                </div>
                                {/* <div className="col-3 text-center">
                                <button className="btn btn-success w-100" type="button" onClick={()=>{sendOnWhatsapp()}} >whatsapp</button>
                                </div>
                                <div className="col-3 text-center">
                                <button className="btn btn-warning w-100" type="button" onClick={()=>{sendOnEmail()}} >Email</button>
                                </div> */}
                                
                                </Form>
                                <div>Result</div>
                                {successResult}
                                {props?.formResult?.error==true && (
                                <div>{props?.formResult?.message}</div>
                                )} 

                                {props?.emailResponse?.success==true || props?.emailResponse?.error==true && (<div>Email Response: {props?.emailResponse.message}</div>)}
                                <br/>
                                {props?.whatsappResponse?.success==true || props?.whatsappResponse?.error==true && (<div>Whatsapp Response: {props?.emailResponse.message}</div>)}
                                <br/>
                                {props?.formResult?.error==true && (<div className="field-error">Save Cron Response: {props?.formResult.message}</div>)}
                                {props?.formResult?.success==true && (<div>Save Cron Response: {props?.formResult.message}</div>)}
                            </FormikProvider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MasterLayout>
    </>
    )
}

export default connect((state)=>{
    return { 
        formResult: { ...state?.Forms?.[stateKey] },
        // list: { ...state.GetData[ruleListKey] },
        // clientlist: { ...state.GetData[clientListKey] },
        emailResponse: { ...state.Forms?.[emailResultKey] },
        whatsappResponse: { ...state.Forms?.[whatsappResultKey] },
        
    };
})(Schedule);