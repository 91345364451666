import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";

const DatabaseDriverDropdown = (props) => {
  const selectInput = useRef();
  //const [dropdownOptions, setDropDownOptions] = useState([]);

  const formik = props.validation;

  useEffect(() => {
    if (
      formik.value == null ||
      formik.value == undefined ||
      formik.value == ""
    ) {
      selectInput.current.clearValue();
    }
  }, [formik.value]);

//   useEffect(() => {
//     let options =
//       props?.list?.data?.length > 0
//         ? props.list.data.map((each) => {
//             return { value: each.id, label: each.email };
//           })
//         : [];

//     setDropDownOptions(options);
//   }, [props.list]);
const dropdownOptions = [
{value:'mysql',label:'mysql'},
{value:'mongo',label:'mongo'},
{value:'oracle',label:'oracle'},
{value:'postgres',label:'postgres'}
]

  return (
    <>
      <Select
        ref={selectInput}
        options={dropdownOptions}
        name={formik.name}
        onChange={(option) => {
            formik?.form?.setFieldValue(formik?.name, option?.value);
        }}
        isInvalid={!!formik.isInvalid}
        placeholder="Select Driver"
        onBlur={() => {
          formik.form.setFieldTouched(formik.name);
        }}
        value={
          dropdownOptions
            ? dropdownOptions.find((option) => {
                return option.value === formik.value;
              })
            : null
        }
        defaultValue={{ value: "", label: "Select Driver " }}
        className={
          formik.error &&
          formik.form.touched[formik.name] == true &&
          `is-invalid`
        }
        openMenuOnFocus={true}
      />
      {formik.form.touched[formik.name] == true && (
        <div className="field-error">{formik.error}</div>
      )}
    </>
  );
};

export default DatabaseDriverDropdown;
