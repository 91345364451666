import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const DeleteConfirmation = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.showConfirmModal);
  }, [props.showConfirmModal]);

  const Proceed = () => {
    setShow(false);
    props.proceedSubmit();
  };

  const Cancel = () => {
    setShow(false);
    props.cancelSubmit();
  };
  return (
    <Modal
      show={show}
      className=""
      onHide={() => {
        return false;
      }}
    >
      <Modal.Body>
        <p className="text-center">
          <b>Are you sure you want to delete this Entry ?</b>
        </p>
        <div className="mt-5">
          <button type="button" className="button col-4" onClick={Proceed}>
            Yes
          </button>
          <button
            type="button"
            className="button col-4 float-end col-offset-1"
            onClick={Cancel}
          >
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DeleteConfirmation;
