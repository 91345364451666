import * as Yup from "yup";
import { notOnlyWhiteSpace } from "../../Constants";

export const listObj = {
    name:"",
    email:"",
    mobile:"",
    dob:"",
    city:"",
    state:"",
    region:"",
    segment:"",
    sales_person:"",
    password:""
};
export const initial_values = { name:"",email:"",mobile:"",dob:"",city:"",state:"",region:"",segment:"",sales_person:"",password:"" };
export const validation_Schema = Yup.object().shape({
    name: Yup.string().required("*Please enter name"),
    email: Yup.string().required("*Please enter email"),
    mobile: Yup.string().required("*Please enter mobile"),
    dob: Yup.string().required("*Please enter date of birth"),
    city: Yup.string().required("*Please enter city"),
    state: Yup.string().required("*Please enter state"),
    region: Yup.string().required("*Please enter region"),
    segment: Yup.string().required("*Please enter segment"),
    sales_person: Yup.string().required("*Please enter sales person name"),
    password: Yup.string().required("*Please enter password")
});
