import * as Yup from "yup";

export const initial_values = { email: "", password: "", captcha_token: "" };
export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  password: Yup.string().required("Password is required"),
  //captcha_token: Yup.string().required("Captcha verification is required"),
  // user_captcha: Yup.string()
  //   .min(6, "*Captcha must have at least 6 characters")
  //   .max(6, "*Captcha can't be longer than 6 characters")
  //   .required("*Captcha is required"),
});
