import { connect } from "react-redux";
import React, { useEffect,useState } from "react";
import { useFormik,Field,FormikProvider,FieldArray } from "formik";
import { initial_values,validation_Schema,listObj } from "./EditUtil";
import { editRule,checkRuleResult,getRuleDetail } from "../../redux/AllThunk/Rules";
import MasterLayout from "../../Layout/MasterLayout";
import { Form,Row,Table } from "react-bootstrap";
import RuleTypeDropdown from "../Common/RuleTypeDropdown";
import { useParams } from "react-router-dom";
import { decrypt } from "../../Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const stateKey="updateRule";
const ruleResultstateKey="checkRuleResult";
const getDetail= "getRuleDetail";

const Edit = (props)=>{

    const [checkRule,saveCheckRule] = useState(false);
    const [resKeys,setResKeys] = useState([]);
    const [resValues,setRestValues] =useState([]);
    const [initialValues,setInitialValues] = useState({...initial_values});
    const { rule_id } = useParams();
    const ruleid = decrypt(rule_id);

    useEffect(()=>{
        props.dispatch(
            getRuleDetail({
                payload: {
                    id:ruleid
                },
                key: getDetail,
            })
        ); 
    },[]);
  
    const submitHandler = (values) => {
        
        if (formik.isValid === true) {
            if(checkRule===true){
                checkResult();
            }else{
                props.dispatch(
                    editRule({
                        payload: {
                            ...values,
                            ruleid:ruleid
                        },
                        key: stateKey,
                    })
                );    
            }
        }
    };


    const formik = useFormik({
        initialValues: initialValues,
        values: initialValues,
        validationSchema: validation_Schema,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });

    useEffect(() => {
        //setLoading(false);

        if (props.formResult.success == true) {
        setTimeout(function () {
            props.dispatch({
            type: "RESET_FORM",
            key: stateKey,
            });
            alert("Rule updated");
            formik.resetForm();
        }, 2000);
        }
    }, [props.formResult]);

    const checkResult = ()=>{
        if (formik.isValid === true) {
            props.dispatch(
                checkRuleResult({
                    payload: formik.values,
                    key: ruleResultstateKey,
                })
            );
        }
    }

    useEffect(()=>{
        let keyArr = [];
        let valueArr = [];
        
        props?.checkResult?.success==true &&  props?.checkResult?.data.length >0 &&
        props?.checkResult?.data.map((value,key)=>{
            let dataArr = [];
            value.map((v,k)=>{
                keyArr[key] = Object.keys(v);
                dataArr[k] = Object.values(v);
            })
            valueArr[key]= dataArr;
        })
        setResKeys(keyArr);
        setRestValues(valueArr);
    },[props?.checkResult])

    useEffect(()=>{
        if(props.getDetail.success==true){
            const listObj = props.getDetail.data.input_array!==null?props.getDetail.data.input_array:[];
            
            const obj = {
                type:props.getDetail.data.type,
                title:props.getDetail.data.title,
                statement:props.getDetail.data.statement,
                inputArray:listObj,
            }
            setInitialValues(obj);
        }
    },[props.getDetail])

    return (
    <MasterLayout>
            <div className="pagetitle">
                <h1>Edit Rule</h1>
            </div>
        
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                    <section className="section">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Rule Type</Form.Label>
                                            <div className="col-sm-4">
                                            <Field name="type">
                                            {({ field, meta, form }) => {
                                                return (
                                                <RuleTypeDropdown
                                                    validation={{
                                                    ...field,
                                                    isInvalid: !!meta.error,
                                                    error: meta.error,
                                                    form: form,
                                                    }}
                                                />
                                                );
                                            }}
                                            </Field>
                                            </div>
                                        </Form.Group>                                 

                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Title</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="title">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Rule Title"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                    
                                        <Form.Group className="row mb-2">
                                            <Form.Label className="col-sm-2 col-form-label">Statement</Form.Label>
                                            <div className="col-sm-10">
                                            <Field name="statement">
                                            {({ field, meta }) => (
                                                <>
                                                <Form.Control
                                                    {...field}
                                                    as="textarea"
                                                    autoComplete="off"
                                                    isInvalid={!!meta.error && !!meta.touched}
                                                    value={field.value ? field.value : ""}
                                                    placeholder="Sql Query"
                                                    
                                                />
                                                {meta.touched && meta.error && (
                                                    <Form.Control.Feedback type="invalid">
                                                    {meta.error}
                                                    </Form.Control.Feedback>
                                                )}
                                                </>
                                            )}
                                            </Field>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="my-2">
                                        <Form.Label className="col-sm-2 col-form-label">Input Fields</Form.Label>

                                            <Row className="mb-3">
                                                <Form.Label className="col-3">Column Name </Form.Label>
                                                <Form.Label className="col-3">Type </Form.Label>
                                                <Form.Label className="col-3">Value </Form.Label>
                                                <Form.Label className="col-3">Description </Form.Label>
                                            </Row>
                                            <FieldArray
                                            name="inputArray"
                                            render={(arrayHelpers) => {
                                                const { push, remove, form } = arrayHelpers;
                                                const { values } = form;
                                                const row =
                                                values !== undefined
                                                    ? values.inputArray?.map((each, index) => {
                                                        return (
                                                        <div
                                                            key={`inputArray${index}`}
                                                            style={{ borderBottom: "2px solid #c1c1c1" }}
                                                        >
                                                            <Row className="d-flex flex-row-reverse">
                                                            <div
                                                                className="col-md-1"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                {/* {index > 0 && (
                                                                <> */}
                                                                    <FontAwesomeIcon
                                                                    icon={faCircleXmark}
                                                                    className="removeFieldIcon"
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                    />
                                                                {/* </>
                                                                )} */}
                                                            </div>
                                                            </Row>
                                                            <Row className="mb-3">
                                                                <Form.Group className="col-3">
                                                                    <Field
                                                                    name={`inputArray[${index}]['name']`}
                                                                    >
                                                                    {({ field, meta }) => (
                                                                        <>
                                                                        <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={!!meta.error && meta.touched}
                                                                            value={field.value ? field.value : ""}
                                                                        />
                                                                        {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                            {meta.error}
                                                                            </div>
                                                                        )}
                                                                        </>
                                                                    )}
                                                                    </Field>
                                                                </Form.Group>
                                                                <Form.Group className="col-3">
                                                                    <Field name={`inputArray.${index}.type`}>
                                                                    {({ field, meta }) => {
                                                                        return (
                                                                        <>
                                                                            <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={
                                                                                !!meta.error && meta.touched
                                                                            }
                                                                            value={field.value ? field.value : ""}
                                                                            />
                                                                            {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                                {meta.error}
                                                                            </div>
                                                                            )}
                                                                        </>
                                                                        );
                                                                    }}
                                                                    </Field>
                                                                </Form.Group>
                                                                <Form.Group className="col-3">
                                                                    <Field name={`inputArray.${index}.value`}>
                                                                    {({ field, meta }) => {
                                                                        return (
                                                                        <>
                                                                            <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={
                                                                                !!meta.error && meta.touched
                                                                            }
                                                                            value={field.value ? field.value : ""}
                                                                            />
                                                                            {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                                {meta.error}
                                                                            </div>
                                                                            )}
                                                                        </>
                                                                        );
                                                                    }}
                                                                    </Field>
                                                                </Form.Group>
                                                                <Form.Group className="col-3">
                                                                    <Field
                                                                    name={`inputArray[${index}]['description']`}
                                                                    >
                                                                    {({ field, meta }) => (
                                                                        <>
                                                                        <Form.Control
                                                                            {...field}
                                                                            autoComplete="off"
                                                                            isInvalid={!!meta.error && meta.touched}
                                                                            value={field.value ? field.value : ""}
                                                                        />
                                                                        {!!meta.error && meta.touched && (
                                                                            <div className="field-error">
                                                                            {meta.error}
                                                                            </div>
                                                                        )}
                                                                        </>
                                                                    )}
                                                                    </Field>
                                                                </Form.Group>
                                                            </Row>
                                                        </div>
                                                        );
                                                    })
                                                    : "";

                                                const result =
                                                values !== undefined ? (
                                                    <>
                                                    {row}
                                                    <div className="form-btns mt-4">
                                                        <button
                                                        type="button"
                                                        className="button"
                                                        onClick={() => push(listObj)}
                                                        >
                                                        +
                                                        </button>
                                                    </div>
                                                    </>
                                                ) : (
                                                    <></>
                                                );
                                                return result;
                                            }}
                                            ></FieldArray> 
                                        </Form.Group>                      
                                        <div className="row mb-3">
                                            <div className="col-6">
                                                <button type="submit" 
                                                className="btn btn-primary"
                                                onClick={()=>{
                                                    saveCheckRule(false);
                                                }}
                                                >Update Rule</button>
                                            </div>
                                            <div className="col-6">
                                                <button type="submit" 
                                                className="btn btn-success" 
                                                onClick={()=>{
                                                    saveCheckRule(true);
                                                }}
                                                >check Result</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Form>
            </FormikProvider>
            <div>
            Result :
            
            {props?.checkResult?.success==true &&  props?.checkResult?.data.length>0 && (
                <Table  responsive >
                {
                    resKeys.length>0 &&
                    resKeys.map((e,i)=>{ 

                    return (
                        <>
                        <thead key={`thead${i}`}>
                        <tr>
                        {e.map((v)=>{
                            return <th key={`th${i}`}>{v}</th>
                        })}
                        </tr>
                        </thead>
                        <tbody key={`tbody${i}`}>
                        {resValues[i].map((value,k)=>{
                            const rw = value.map((v)=>{
                                return <td key={`td${i}`}>{v}</td>
                            })
                            return <tr key={`tbody-r${k}`} >{rw}</tr>
                        })}
                        </tbody>
                        </>
                    )
                    })
                }
                </Table>
            )}
            {props?.checkResult?.success==true &&  props?.checkResult?.data.length==0 && (
                <div>Data Not Found</div>
            )}
            {props?.checkResult?.error==true && (<div className="field-error">{props?.checkResult?.message}</div> )}
            </div>
    </MasterLayout>
    )
}

export default connect((state)=>{
    return {
        formResult: { ...state?.Forms?.[stateKey] }, 
        checkResult: { ...state?.Forms?.[ruleResultstateKey] },
        getDetail: { ...state?.GetData?.[getDetail] }
    }
})(Edit)