import * as Yup from "yup";

export const listObj = {
    name: "",
    type: "",
    description: "",
    value:"",
};
export const initial_values = { rule_id: "", client_id:"", inputArray: [],send_via:[] };
export const validationSchema = Yup.object().shape({
  rule_id: Yup.number().required("*Rule No. is required")
  .typeError("A number is required"),

});
