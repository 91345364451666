import { whatsapp_clientId } from "../Constants";
import CryptoJS from "crypto-js";
import { encryptSecret } from "../Constants";


export function encrypt(data) {
    return CryptoJS.Rabbit.encrypt(JSON.stringify(data), encryptSecret)
    .toString()
    .replace(/\//g, "Por21Ld");
}

export function decrypt(data) {
    var bytes = CryptoJS.Rabbit.decrypt(
        data.replaceAll("Por21Ld", "/"),
        encryptSecret
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export function dateString(date, sign = "-") {

    if(date !==undefined){
        var givenDate = new Date(date),
        month = "" + (givenDate.getMonth() + 1),
        day = "" + givenDate.getDate(),
        year = givenDate.getFullYear();
    }else{
        var givenDate = new Date(),
        month = "" + (givenDate.getMonth() + 1),
        day = "" + givenDate.getDate(),
        year = givenDate.getFullYear();
    }
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [year, month, day].join(sign);
}

export const stringFromObj = (obj) => {
    let str="";
    for (const [g, h] of Object.entries(obj)) {
        str += `${g}: ${h} `;
    }
    return <div>{str}</div>;
    //finalStrArr.push(str1);
    // str2 += <div>{str1}</div>;

};

export const Whatsapp_msg_template = (data)=>{
    const template = 
    {
        "clientId": whatsapp_clientId,
        "to": data.receiver,
        "type": "template",
        "typeBody": {
            "name": "neterra_catalog_send",
            "language": {
                "code": "en",
                "policy": "deterministic"
            },
            "components": [
                {
                    "type": "header",
                    "parameters": [
                        {
                            "type": "document",
                            "document": {
                                "link": data.filepath,
                                "filename":data.filename
                            }
                        }
                    ]
                },
                {
                    "type": "body",
                    "parameters": [
                        {
                            "type": "text",
                            "text": data.catalogName
                        },
                    ]
                }
            ]
        }
    }
    return template;
}