import React, { useEffect,useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import MasterLayout from "../../Layout/MasterLayout";
import { ruleList,deleteRule } from "../../redux/AllThunk/Rules";
import DeleteConfirmation from "../Common/DeleteConfirmation";
import { encrypt } from "../../Helper";
import { Link } from "react-router-dom";

const ruleListKey = "ruleslist";
const deleteRuleKey = "deleteRule";

const List = (props)=>{
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteRuleId, setDeleteRuleId] = useState(0);

    useEffect(()=>{
        getRuleList();
    },[])

    const proceedDelete = () => {
        setShowDeleteModal(false);

        props.dispatch(deleteRule({
          payload: { id: deleteRuleId },
          key: deleteRuleKey,
        }));
    };

    const cancelDelete = () => {
        setShowDeleteModal(false);
    };

    function getRuleList(){
        props.dispatch(
            ruleList({
                key: ruleListKey,
            })
        );
    }

    useEffect(() => {
        if (props.deleteRule.success == true) {
            getRuleList();
            alert("Rule Deleted successfully");
            props.dispatch({
            type: "RESET_FORM",
            key: deleteRuleKey,
            });
            setDeleteRuleId(0);
            setShowDeleteModal(false);
        }
    }, [props.deleteRule]);

    return (
        <MasterLayout>
        <DeleteConfirmation
        showConfirmModal={showDeleteModal}
        proceedSubmit={proceedDelete}
        cancelSubmit={cancelDelete}
        />
        <div className="pagetitle">
            <h1>Rules List</h1>
        </div>
        <section className="section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"></h5>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>Unique Id</td>
                                        <td>Type</td>
                                        <td>Title</td>
                                        <td>Statement</td>
                                        <td>Fields</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.list?.success==true && props.list?.data.length>0 && 
                                        props.list?.data.map((value,key)=>{
                                            return <tr key={`tr${key}`}>
                                                <td>{key+1}</td>
                                                <td>{value.unique_id}</td>
                                                <td>{value.type}</td>
                                                <td>{value.title}</td>
                                                <td>{value.statement}</td>
                                                <td>{value?.input_array?.map((v,i)=>{
                                                    return (
                                                    <React.Fragment key={`d${i}`}>
                                                    <b>{v.name}</b>
                                                    <div>
                                                    {`${v.type}:${v.value !==undefined && v.value}:${v.description}\n`}
                                                    </div>
                                                    </React.Fragment>)
                                                })}</td>
                                                <td>
                                                    <Link to={`/edit-rule/${encrypt(value.id)}`}>
                                                    <button className="btn btn-primary">
                                                    Edit
                                                    </button>
                                                    </Link>
                                                    <Link to={`/execute-rule/${encrypt(value.id)}`}>
                                                    <button  className="btn btn-warning">
                                                    Execute
                                                    </button>
                                                    </Link>
                                                    <Link to={`/schedule-rule/${encrypt(value.id)}`}>
                                                    <button className="btn btn-success">
                                                    Schedule
                                                    </button>
                                                    </Link>
                                                    <button 
                                                    className="btn btn-danger"
                                                    onClick={()=>{
                                                        setDeleteRuleId(value.id);
                                                        setShowDeleteModal(true);
                                                    }}>Delete</button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MasterLayout>
    )
}


export default connect((state)=>{
    return {
        list:{...state.GetData[ruleListKey]},
        deleteRule:{...state.Forms[deleteRuleKey]},
    }
})(List)