import React,{ useEffect,useState } from "react";
import { connect } from "react-redux";
import { useFormik,Field,FormikProvider } from "formik";
import { addClientDatabase } from "../../redux/AllThunk/Client";
import MasterLayout from "../../Layout/MasterLayout";
import { initial_values,validation_Schema } from "./addDbUtil";
import { Form } from "react-bootstrap";
import ClientListDropdown from "../Common/ClientListDropdown";
import DatabaseDriverDropdown from "../Common/DatabaseDriverDropdown";
import {clientList} from "../../redux/AllThunk/Rules";

const stateKey="saveClientDatabase";
const clientListKey = "clientlist";

const AddDatabase = (props)=>{
       
    useEffect(()=>{
        props.dispatch(
            clientList({
                payload: {},
                key: clientListKey,
            })
        );
    },[])

    const submitHandler = (values) => {
        if (formik.isValid === true) {
            const params = {
                host:values.host,
                port:values.port,
                database:values.database_name,
                username:values.username,
                password:values.password,
                engine:values.driver,
                service_name:values.service_name
            }
            props.dispatch(
                addClientDatabase({
                    payload: {
                        driver:values.driver,
                        client_id:values.client_id,
                        params:params,
                    },
                    key: stateKey,
                })
            );    
        }
    };

    const formik = useFormik({
        initialValues: initial_values,
        values: initial_values,
        validationSchema: validation_Schema,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });

    //console.log('----formik',    formik.values,formik.errors);

    useEffect(() => {
        //setLoading(false);
        if (props.formResult.success == true) {
            setTimeout(function () {
                props.dispatch({
                type: "RESET_FORM",
                key: stateKey,
                });
                formik.resetForm();
            }, 50000);
        }
    }, [props.formResult]);
    console.log('----,',props.formResult);

    return (
        <MasterLayout>
            <div className="pagetitle">
                <h1>Add Client Database</h1>
            </div>
            <section className="section">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body"> 
                            <h5 className="card-title text-success">
                            {props.formResult?.success==true && 'Client Database addedd successfully'}
                            </h5>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit} >
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Select Client </Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="client_id">
                                    {({ field, meta, form }) => {
                                        return (
                                        <ClientListDropdown
                                            validation={{
                                            ...field,
                                            isInvalid: !!meta.error,
                                            error: meta.error,
                                            form: form,
                                            }}
                                        />
                                        );
                                    }}
                                    </Field>
                                    </div>
                                </Form.Group>                          
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Database Driver</Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="driver">
                                    {({ field, meta, form }) => {
                                        return (
                                        <DatabaseDriverDropdown
                                            validation={{
                                            ...field,
                                            isInvalid: !!meta.error,
                                            error: meta.error,
                                            form: form,
                                            }}
                                        />
                                        );
                                    }}
                                    </Field>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Service name</Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="service_name">
                                    {({ field, meta }) => (
                                        <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={!!meta.error && !!meta.touched}
                                            value={field.value ? field.value : ""}
                                            placeholder="Enter Service Name"
                                        />
                                        {meta.touched && meta.error && (
                                            <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                            </Form.Control.Feedback>
                                        )}
                                        </>
                                    )}
                                    </Field>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Host</Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="host">
                                    {({ field, meta }) => (
                                        <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={!!meta.error && !!meta.touched}
                                            value={field.value ? field.value : ""}
                                            placeholder="Enter Host"
                                        />
                                        {meta.touched && meta.error && (
                                            <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                            </Form.Control.Feedback>
                                        )}
                                        </>
                                    )}
                                    </Field>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Port</Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="port">
                                    {({ field, meta }) => (
                                        <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={!!meta.error && !!meta.touched}
                                            value={field.value ? field.value : ""}
                                            placeholder="Enter Port"
                                        />
                                        {meta.touched && meta.error && (
                                            <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                            </Form.Control.Feedback>
                                        )}
                                        </>
                                    )}
                                    </Field>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Database Name</Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="database_name">
                                    {({ field, meta }) => (
                                        <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={!!meta.error && !!meta.touched}
                                            value={field.value ? field.value : ""}
                                            placeholder="Enter database name"
                                        />
                                        {meta.touched && meta.error && (
                                            <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                            </Form.Control.Feedback>
                                        )}
                                        </>
                                    )}
                                    </Field>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Database username</Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="username">
                                    {({ field, meta }) => (
                                        <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={!!meta.error && !!meta.touched}
                                            value={field.value ? field.value : ""}
                                            placeholder="Enter username"
                                        />
                                        {meta.touched && meta.error && (
                                            <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                            </Form.Control.Feedback>
                                        )}
                                        </>
                                    )}
                                    </Field>
                                    </div>
                                </Form.Group>
                                <Form.Group className="row mb-2">
                                    <Form.Label className="col-sm-3 col-form-label">Database Password</Form.Label>
                                    <div className="col-sm-4">
                                    <Field name="password">
                                    {({ field, meta }) => (
                                        <>
                                        <Form.Control
                                            {...field}
                                            autoComplete="off"
                                            isInvalid={!!meta.error && !!meta.touched}
                                            value={field.value ? field.value : ""}
                                            placeholder="Database Password"
                                        />
                                        {meta.touched && meta.error && (
                                            <Form.Control.Feedback type="invalid">
                                            {meta.error}
                                            </Form.Control.Feedback>
                                        )}
                                        </>
                                    )}
                                    </Field>
                                    </div>
                                </Form.Group>
                                                
                                <div className="row mb-3">
                                    <div className="col-6">
                                        <button type="submit" 
                                        className="btn btn-primary"
                                        >Add Client Database</button>
                                    </div>
                                </div>
                                </Form>
                            </FormikProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MasterLayout>
    )
}
export default connect((state)=>{
    return { 
        formResult: { ...state?.Forms?.[stateKey] }, 
    };
})(AddDatabase)