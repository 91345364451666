import React from 'react';
import { useEffect,useState,useRef } from 'react';
import { connect } from 'react-redux';
import { Login as loginthunk } from '../../redux/AllThunk/Login';
import { FormikProvider, useFormik, Field } from "formik";
import { initial_values,validationSchema } from './util';
import PassToggle from '../Common/PassToggle';
import { Form } from 'react-bootstrap';
import { dashboard } from '../../route';
import { useNavigate } from 'react-router-dom';
import Captcha from '../Common/Captcha';

const stateKey="Login"
const Login = (props)=>{
    const navigate = useNavigate();
    const captchaRef = useRef(null)
    const submitHandler = (values) => {
    
        if (formik.isValid === true) {
            props.dispatch(
                loginthunk({
                    payload: {
                        email: values.email,
                        password: values.password,
                        captcha_token:captchaRef.current.getValue(),
                    },
                    key: stateKey,
                })
            );
        }
    };

    const formik = useFormik({
        initialValues: initial_values,
        values: initial_values,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: submitHandler,
    });

    console.log('---',props.formResult);
    useEffect(() => {
        //setLoading(false);
    
        if (props.formResult.success == true) {
          setTimeout(function () {
            props.dispatch({
              type: "RESET_FORM",
              key: stateKey,
            });
            
            //navigate(dashboard);
            window.location.href = dashboard;
          }, 2000);
        }else{
            captchaRef.current.reset();
        }
    }, [props.formResult]);


    
    return ( 
    <main>
        <div className="container">

        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div className="d-flex justify-content-center py-4">
                    <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <span className="d-none d-lg-block">Rule Engine</span>
                    </a>
                </div>

                <div className="card mb-3">
                    <div className="card-body">
                    <div className="pt-2 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    </div>

                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit} className="login-form row g-3 needs-validation">
                        <Form.Group className="col-md-12 mb-2">
                            <Field name="email">
                            {({ field, meta }) => (
                                <>
                                <Form.Control
                                    {...field}
                                    type="email"
                                    autoComplete="off"
                                    isInvalid={!!meta.error && !!meta.touched}
                                    value={field.value ? field.value : ""}
                                    placeholder="Email ID"
                                />
                                {meta.touched && meta.error && (
                                    <Form.Control.Feedback type="invalid">
                                    {meta.error}
                                    </Form.Control.Feedback>
                                )}
                                </>
                            )}
                            </Field>
                        </Form.Group>
                        <Form.Group className="col-md-12 mb-2 position-relative">
                            <PassToggle name="password" placeholder="Password" />
                        </Form.Group>
                        {props?.formResult?.error==true && <div className="field-error">{props?.formResult?.message}</div>}
                        {props?.formResult?.error==true && props?.formResult?.message==undefined && <div className="field-error">Something Went wrong</div>}
                        <Captcha 
                        //onChange={onCaptchaChange}
                        reference={captchaRef}
                        />
                        
                        {formik.touched.captcha_token && formik.errors.captcha_token !==undefined && (
                            <Form.Control.Feedback type="invalid" style={{display:"block"}}>
                            {formik.errors.captcha_token}
                            </Form.Control.Feedback>
                        )}
                        <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit" >Login</button>
                        </div>
                    
                        </Form>
                    </FormikProvider>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        </div>
    </main>
  )
}
export default connect((state)=>{
    return { formResult: { ...state?.Forms?.[stateKey] } };
})(Login)