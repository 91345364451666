import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PassToggle = (props) => {
  const [passShow, setPassShow] = useState(false);
  const [c_passShow, setC_PassShow] = useState(false);

  const passHandler = () => {
    setPassShow(!passShow);
  };

  const c_passHandler = () => {
    setC_PassShow(!c_passShow);
  };

  return (
    <>
      <Field name={props.name}>
        {({ field, meta }) => (
          <>
            <Form.Control
              {...field}
              type={passShow == true ? `text` : `password`}
              autoComplete="off"
              isInvalid={!!meta.error && !!meta.touched}
              value={field.value ? field.value : ""}
              placeholder={props.placeholder}
              className="password"
            />
            {meta.touched && meta.error && (
              <Form.Control.Feedback type="invalid">
                {meta.error}
              </Form.Control.Feedback>
            )}
          </>
        )}
      </Field>
      {/* <FontAwesomeIcon
        className={
          passShow
            ? `d-block toggleEye position-absolute`
            : `d-none toggleEye position-absolute`
        }
        onClick={() => {
          passHandler();
        }}
        icon={faEye}
      />
      <FontAwesomeIcon
        className={
          passShow
            ? `d-none toggleEye position-absolute`
            : `d-block toggleEye position-absolute`
        }
        onClick={() => {
          passHandler();
        }}
        icon={faEyeSlash}
      /> */}
    </>
  );
};
export default PassToggle;
